body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  background: #8C46AA;
  background: -webkit-linear-gradient(bottom, #8C46AA, #B69EE2);
  background: -moz-linear-gradient(bottom, #8C46AA, #B69EE2);
  background: linear-gradient(to top, #8C46AA, #B69EE2);
  height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  position: relative;
  max-width: 60vh;
  margin: auto;
}

.App {
}

.bg {
  height: 105vh;
}

.bg.center{
  width: 100vh;
  position: absolute;
  left: -55%;
}

.overlay {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; 
  opacity: .8;
  z-index: 1000;
}

.overlay-top {
  top: 0;
  position:absolute;
  background-color: rgba(255, 255,255, 0.85);
  color: black; 
  opacity: .8;
  z-index: 1000;
  width: 90%;
  margin: 5%;
}

.overlay-bottom {
  position:absolute;
  top:88%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; 
  opacity: .8;
  z-index: 1000;
}

.overlay img {
  border-radius: 10px;
}

.message-image {
  width: 35vh;
}

.message-text {
  text-align: left;
  border-radius: 10px;
  background: white;
  padding: 10px;
  color: black;
  font-size: larger;
  font-weight: bold;
}

.prince-text {
  background: white;
  padding: 10px;
  color: black;
  font-size: larger;
  font-weight: bold;
  width: 100vh;
}

.last-text {
  background: white;
  padding: 10px;
  color: black;
  font-size: larger;
  font-weight: bold;
  width: 100vh;
}

.card {
  border-bottom: 1px solid white;
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow {
  border: solid white;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
}

.icon {
  padding: 15px;
  border: 2px solid;
  border-radius: 100px !important;
  background-color: rgba(255, 255,255, 0.35);
  margin-bottom: 20px;
}

.icon-text{
  font-size: xx-large;
  width: 100vh;
  color: #8C46AA;
  font-weight: bold;
  background-color: rgba(255, 255,255, 0.65);
  font-style: italic;
}

.icon-content{
  margin-top: 10px;
  width: 100vh;
  color: #8C46AA;
  font-size: larger;
  font-weight: bold;
  background-color: rgba(255, 255,255, 0.65);
}

.black{
  border-color: rgba(0, 0,0, 0.8) !important;
}

.mymessage-text {
  text-align: left;
  background: white;
  padding: 10px;
  color: black;
  font-weight: normal;
  font-size: 14px;
  width: 40vh;
}
